"use strict";

/**
 * This adds a placeholder for jQuery.ready
 * that can be used before jquery loads
 */
(function (w, d, u) {

    // Define two queues for handlers
    w.readyQ = [];
    w.bindReadyQ = [];

    // Push a handler into the correct queue
    function pushToQ(x, y) {
        if (x == "ready") {
            w.bindReadyQ.push(y);
        } else {
            w.readyQ.push(x);
        }
    }

    // Define an alias object (for use later)
    var alias = {
        ready: pushToQ,
        bind: pushToQ
    };

    // Define the fake jQuery function to capture handlers
    w.$ = w.jQuery = function (handler) {
        if (handler === d || handler === u) {
            // Queue $(document).ready(handler), $().ready(handler)
            // and $(document).bind("ready", handler) by returning
            // an object with alias methods for pushToQ
            return alias;
        } else {
            // Queue $(handler)
            pushToQ(handler);
        }
    };

    //add a method on the window that pushed all queued items through jquery
    window.processReadyQueue = function(){
        (function ($, doc) {
            $.each(readyQ, function (index, handler) {
                $(handler);
            });
            $.each(bindReadyQ, function (index, handler) {
                $(doc).bind("ready", handler);
            });
        })(jQuery, document);
    }

})(window, document);